import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import AddEditResource from 'components/shared/AddEditResource';
import countriesArr from 'utils/countriesList';
import { toApiValues, toFormValues } from 'components/shared/resource/api-form-mapper';

import EditOnboardingApplicationForm from './EditOnboardingApplicationForm';
import onboardingApplicationStatusChoices from './OnboardingApplicationStatusChoices';

const apiEndpoint = 'admin/infinity/onboarding-applications';
const adminEndpoint = 'infinity/onboarding-applications';
const resourceName = 'onboarding application ';

const validationSchema = Yup.object().shape({});

const countryChoices = countriesArr.map((choice) => ({ value: choice[0], label: choice[1] }));

const EditOnboardingApplication = () => {
  const params = useParams();

  const id = params.id ? Number(params.id) : null;

  const fields = [
    {
      name: 'schoolName',
      label: 'School Name',
      section: 'wondeInfo',
      flex: 6,
    },
    {
      name: 'schoolPostcode',
      label: 'School Postcode',
      section: 'wondeInfo',
      flex: 6,
    },
    {
      name: 'urn',
      label: 'School URN',
      section: 'wondeInfo',
      flex: 6,
    },
    {
      name: 'dfeNumber',
      label: 'School DfE',
      section: 'wondeInfo',
      flex: 6,
    },
    {
      name: 'misType',
      label: 'MIS type',
      section: 'wondeInfo',
      flex: 6,
    },
    {
      name: 'wondeIntegrated',
      label: 'Wonde integrated?',
      section: 'wondeInfo',
      type: 'checkbox',
      flex: 12,
    },
    {
      name: 'studentCount',
      label: 'Student Count',
      type: 'number',
      flex: 6,
    },
    {
      name: 'basket.billingAddress.company',
      label: 'Company',
      type: 'text',
      section: 'billing',
      flex: 4,
    },
    {
      name: 'basket.billingAddress.line1',
      label: 'Address line 1',
      type: 'text',
      section: 'billing',
      flex: 4,
    },
    {
      name: 'basket.billingAddress.line2',
      label: 'Address line 2',
      type: 'text',
      section: 'billing',
      flex: 4,
    },
    {
      name: 'basket.billingAddress.townCity',
      label: 'Town/city',
      type: 'text',
      section: 'billing',
      flex: 4,
    },
    {
      name: 'basket.billingAddress.postcode',
      label: 'Postcode',
      type: 'text',
      section: 'billing',
      flex: 4,
    },
    {
      name: 'basket.billingAddress.country',
      label: 'Country',
      type: 'select',
      choices: countryChoices,
      section: 'billing',
      defaultValue: 'GBR',
      flex: 4,
    },
    {
      name: 'basket.billingAddress.firstName',
      label: 'First name',
      type: 'text',
      section: 'user',
      flex: 6,
    },
    {
      name: 'basket.billingAddress.lastName',
      label: 'Last name',
      type: 'text',
      section: 'user',
      flex: 6,
    },
    {
      name: 'basket.billingAddress.phone',
      label: 'Phone number',
      type: 'text',
      section: 'user',
      flex: 6,
    },
    {
      name: 'basket.billingAddress.email',
      label: 'Email',
      type: 'text',
      section: 'user',
      flex: 6,
    },
    {
      name: 'basket.manualDiscount',
      label: 'Discount',
      type: 'currency',
      section: 'discount',
      flex: 3,
    },
  ];

  const toApiValuesCustom = (formValues) => {
    const apiValues = toApiValues(formValues, fields);

    // Do not update basket via onboarding application form
    delete apiValues.basket
    return apiValues;
  };

  // eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // Get status string
  const statusChoice = onboardingApplicationStatusChoices.find(status => status.value === resource.status);
  formValues.status = statusChoice.label ?? '';

  return formValues;
}

  return (
    <AddEditResource
      addEditForm={EditOnboardingApplicationForm}
      apiEndpoint={apiEndpoint}
      adminEndpoint={adminEndpoint}
      toFormValuesCustom={toFormValuesCustom}
      fields={fields}
      id={id}
      toApiValuesCustom={toApiValuesCustom}
      resourceName={resourceName}
      validationSchema={validationSchema}
      redirectToEdit
    />
  );
};

export default EditOnboardingApplication;
