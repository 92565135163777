// MUI
import Icon from '@mui/material/Icon';

// WRM
import Login from 'pages/unauth/Login';
import Analytics from 'pages/auth/Analytics/Analytics';
import DiscountAnalytics from 'pages/auth/Analytics/DiscountAnalytics';
import MembershipAnalytics from 'pages/auth/Analytics/MembershipAnalytics';
import MultiFactorAuthentication from 'pages/auth/Auth/MultiFactorAuthentication';
import Dashboard from 'pages/auth/Dashboard/Dashboard';
import DeepDiveReports from 'pages/auth/DeepDiveReports/DeepDiveReports';
import AddEditDeepDiveReport from 'pages/auth/DeepDiveReports/AddEditDeepDiveReport';
import DigitalTools from 'pages/auth/DigitalTools/DigitalTools';
import AddEditDigitalTool from 'pages/auth/DigitalTools/AddEditDigitalTool';
import AddEditTrainingCourse from 'pages/auth/TrainingCourses/AddEditTrainingCourse';
import FaceToFaceCourses from 'pages/auth/FaceToFaceCourses/FaceToFaceCourses';
import AddEditFaceToFaceCourse from 'pages/auth/FaceToFaceCourses/AddEditFaceToFaceCourse';
import HomeLearningPages from 'pages/auth/HomeLearningPages/HomeLearningPages';
import AddEditHomeLearningPage from 'pages/auth/HomeLearningPages/AddEditHomeLearningPage';
import IplicitOrderExports from 'pages/auth/IplicitOrderExports/IplicitOrderExports';
import AddEditIplicitOrderExport from 'pages/auth/IplicitOrderExports/AddEditIplicitOrderExport';
import IplicitProductCodes from 'pages/auth/IplicitProductCodes/IplicitProductCodes';
import AddEditIplicitProductCode from 'pages/auth/IplicitProductCodes/AddEditIplicitProductCode';
import IplicitProductPromotionCodes from 'pages/auth/IplicitProductPromotionCodes/IplicitProductPromotionCodes';
import AddEditIplicitProductPromotionCode from 'pages/auth/IplicitProductPromotionCodes/AddEditIplicitProductPromotionCode';
import Locations from 'pages/auth/Locations/Locations';
import AddEditLocation from 'pages/auth/Locations/AddEditLocation';
import Media from 'pages/auth/Media/Media';
import Menus from 'pages/auth/Menus/Menus';
import AddEditMenu from 'pages/auth/Menus/AddEditMenu';
import Memberships from 'pages/auth/Memberships/Memberships';
import AddEditMembership from 'pages/auth/Memberships/AddEditMembership';
import MembershipPlans from 'pages/auth/MembershipPlans/MembershipPlans';
import AddEditMembershipPlan from 'pages/auth/MembershipPlans/AddEditMembershipPlan';
import NewsArticles from 'pages/auth/NewsArticles/NewsArticles';
import AddEditNewsArticle from 'pages/auth/NewsArticles/AddEditNewsArticle';
import PageBlockTypes from 'pages/auth/PageBlockTypes/PageBlockTypes';
import AddEditPageBlockType from 'pages/auth/PageBlockTypes/AddEditPageBlockType';
import Orders from 'pages/auth/Orders/Orders';
import AddOrder from 'pages/auth/Orders/AddOrder';
import EditOrder from 'pages/auth/Orders/EditOrder';
import OrdersWarehouse from 'pages/auth/Warehouse/Orders/Orders';
import EditOrderWarehouse from 'pages/auth/Warehouse/Orders/EditOrder';
import OrderReports from 'pages/auth/OrderReports/OrderReports';
import AddEditOrderReport from 'pages/auth/OrderReports/AddEditOrderReport';
import PaymentTransactions from 'pages/auth/PaymentTransactions/PaymentTransactions';
import EditPaymentTransaction from 'pages/auth/PaymentTransactions/EditPaymentTransaction';
import Products from 'pages/auth/Products/Products';
import AddEditProduct from 'pages/auth/Products/AddEditProduct';
import ProductAttributes from 'pages/auth/ProductAttributes/ProductAttributes';
import AddEditProductAttribute from 'pages/auth/ProductAttributes/AddEditProductAttribute';
import ProductAttributeGroups from 'pages/auth/ProductAttributeGroups/ProductAttributeGroups';
import AddEditProductAttributeGroup from 'pages/auth/ProductAttributeGroups/AddEditProductAttributeGroup';
import ProductAttributeSets from 'pages/auth/ProductAttributeSets/ProductAttributeSets';
import AddEditProductAttributeSet from 'pages/auth/ProductAttributeSets/AddEditProductAttributeSet';
import ProductBundles from 'pages/auth/Products/ProductBundles';
import ProductCategories from 'pages/auth/ProductCategories/ProductCategories';
import AddEditProductCategory from 'pages/auth/ProductCategories/AddEditProductCategory';
import ProductReportingCategories from 'pages/auth/ProductReportingCategories/ProductReportingCategories';
import AddEditProductReportingCategory from 'pages/auth/ProductReportingCategories/AddEditProductReportingCategory';
import StockChanges from 'pages/auth/StockChange/StockChanges';
import AddEditStockChange from 'pages/auth/StockChange/AddEditStockChange';
import StockAuditReport from 'pages/auth/ProductReports/StockAuditReport';
import Promotions from 'pages/auth/Promotions/Promotions';
import AddEditPromotion from 'pages/auth/Promotions/AddEditPromotion';
import SingleUseDiscounts from 'pages/auth/Promotions/SingleUseDiscounts';
import AddEditSingleUseDiscount from 'pages/auth/Promotions/AddEditSingleUseDiscount';
import Quotes from 'pages/auth/Quotes/Quotes';
import EditQuote from 'pages/auth/Quotes/EditQuote';
import OrderRefunds from 'pages/auth/Orders/OrderRefunds';
import Redirects from 'pages/auth/Redirects/Redirects';
import AddEditRedirect from 'pages/auth/Redirects/AddEditRedirect';
import ResourcePages from 'pages/auth/ResourcePages/ResourcePages';
import AddEditResourcePage from 'pages/auth/ResourcePages/AddEditResourcePage';
import SageOrderExports from 'pages/auth/SageOrderExports/SageOrderExports';
import AddEditSageOrderExport from 'pages/auth/SageOrderExports/AddEditSageOrderExport';
import SageProductCodes from 'pages/auth/SageProductCodes/SageProductCodes';
import AddEditSageProductCode from 'pages/auth/SageProductCodes/AddEditSageProductCode';
import SageProductPromotionCodes from 'pages/auth/SageProductPromotionCodes/SageProductPromotionCodes';
import AddEditSageProductPromotionCode from 'pages/auth/SageProductPromotionCodes/AddEditSageProductPromotionCode';
import ShippingServices from 'pages/auth/ShippingServices/ShippingServices';
import AddEditShippingService from 'pages/auth/ShippingServices/AddEditShippingService';
import TeamMemberships from 'pages/auth/TeamMemberships/TeamMemberships';
import AddEditTeamMembership from 'pages/auth/TeamMemberships/AddEditTeamMembership';
import Users from 'pages/auth/Users/Users';
import AddEditUser from 'pages/auth/Users/AddEditUser';
import UserAuthenticators from 'pages/auth/UserAuthenticators/UserAuthenticators';
import VideoCourses from 'pages/auth/VideoCourses/VideoCourses';
import AddEditVideoCourse from 'pages/auth/VideoCourses/AddEditVideoCourse';
import WebComponents from 'pages/auth/WebComponents/WebComponents';
import AddEditWebComponent from 'pages/auth/WebComponents/AddEditWebComponent';
import WebinarCourses from 'pages/auth/WebinarCourses/WebinarCourses';
import AddEditWebinarCourse from 'pages/auth/WebinarCourses/AddEditWebinarCourse';
import WebPages from 'pages/auth/WebPages/WebPages';
import AddEditWebPage from 'pages/auth/WebPages/AddEditWebPage';
import AddStockCountRequest from 'pages/auth/StockChange/AddStockCountRequest';
import AddStockReceivedRequest from 'pages/auth/StockChange/AddStockReceivedRequest';
import AddStockOrderRequest from 'pages/auth/StockChange/AddStockOrderRequest';
import AddStockCorrectionRequest from 'pages/auth/StockChange/AddStockCorrectionRequest';
import StockAuditDrawDownReport from 'pages/auth/ProductReports/StockAuditDrawDownReport';
import AddStockTransferRequest from 'pages/auth/StockChange/AddStockTransferRequest';
import AddStockWriteOffRequest from 'pages/auth/StockChange/AddStockWriteOffRequest';
import DownloadReport from 'pages/auth/Report/DownloadReport';
import SchoolLearningGroups from 'pages/auth/LearningGroups/SchoolLearningGroups';
import AddEditSchoolLearningGroup from 'pages/auth/LearningGroups/AddEditSchoolLearningGroup';
import Licences from 'pages/auth/Licences/Licences';
import AddEditLicence from 'pages/auth/Licences/AddEditLicence';
import LearningGroups from 'pages/auth/LearningGroups/LearningGroups';
import AddEditCustomLearningGroup from 'pages/auth/LearningGroups/AddEditCustomLearningGroup';
import CustomLearningGroups from 'pages/auth/LearningGroups/CustomLearningGroups';
import QuizVersions from 'pages/auth/Infinity/QuizVersions';
import InfinityConfiguration from 'pages/auth/LearningGroups/InfinityConfiguration';
import OnboardingApplications from 'pages/auth/Infinity/OnboardingApplication/OnboardingApplications';
import EditOnboardingApplication from 'pages/auth/Infinity/OnboardingApplication/EditOnboardingApplication';
import AddOnboardingApplication from 'pages/auth/Infinity/OnboardingApplication/AddOnboardingApplication';
import TrainingCourses from './pages/auth/TrainingCourses/TrainingCourses';

export const authRoutes = [
  {
    route: '/auth/login',
    component: Login,
  },
];

export const adminHiddenRoutes = [
  { route: '/admin/auth/mfa', component: MultiFactorAuthentication },
  { route: '/admin/deep-dive-reports/edit/:id', component: AddEditDeepDiveReport, role: 'ROLE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/deep-dive-reports/add', component: AddEditDeepDiveReport, role: 'ROLE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/deep-dive-reports', component: DeepDiveReports, role: 'ROLE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/digital-tools/edit/:id', component: AddEditDigitalTool, role: 'ROLE_DIGITAL_TOOL_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/digital-tools/add', component: AddEditDigitalTool, role: 'ROLE_DIGITAL_TOOL_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/face-to-face-courses/edit/:id', component: AddEditFaceToFaceCourse, role: 'ROLE_FACE_TO_FACE_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/face-to-face-courses/add', component: AddEditFaceToFaceCourse, role: 'ROLE_FACE_TO_FACE_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/training-courses/edit/:id', component: AddEditTrainingCourse, role: 'ROLE_FACE_TO_FACE_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/training-courses/add', component: AddEditTrainingCourse, role: 'ROLE_FACE_TO_FACE_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/home-learning-pages/edit/:id', component: AddEditHomeLearningPage, role: 'ROLE_HOME_LEARNING_PAGE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/home-learning-pages/add', component: AddEditHomeLearningPage, role: 'ROLE_HOME_LEARNING_PAGE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/iplicit-order-exports', component: IplicitOrderExports, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/iplicit-order-exports/edit/:id', component: AddEditIplicitOrderExport, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/iplicit-product-codes', component: IplicitProductCodes, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/iplicit-product-codes/edit/:id', component: AddEditIplicitProductCode, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/iplicit-product-promotion-codes', component: IplicitProductPromotionCodes, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/iplicit-product-promotion-codes/edit/:id', component: AddEditIplicitProductPromotionCode, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/locations/edit/:id', component: AddEditLocation, role: 'ROLE_LOCATION_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/locations/add', component: AddEditLocation, role: 'ROLE_LOCATION_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/menus/edit/:id', component: AddEditMenu, role: 'ROLE_MENU_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/menus/add', component: AddEditMenu, role: 'ROLE_MENU_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/memberships/edit/:id', component: AddEditMembership, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/memberships/add', component: AddEditMembership, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/membership-plans/edit/:id', component: AddEditMembershipPlan, role: 'ROLE_MEMBERSHIP_PLAN_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/membership-plans/add', component: AddEditMembershipPlan, role: 'ROLE_MEMBERSHIP_PLAN_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/news-articles/edit/:id', component: AddEditNewsArticle, role: 'ROLE_NEWS_ARTICLE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/news-articles/add', component: AddEditNewsArticle, role: 'ROLE_NEWS_ARTICLE_ADMIN\'', layout: '/admin', hide: true },
  { route: '/admin/orders/edit/:id', component: EditOrder, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/orders/add', component: AddOrder, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/page-block-types/edit/:id', component: AddEditPageBlockType, role: 'ROLE_PAGE_BLOCK_TYPE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/page-block-types/add', component: AddEditPageBlockType, role: 'ROLE_PAGE_BLOCK_TYPE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/payment-transactions/edit/:id', component: EditPaymentTransaction, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/order-reports/edit/:id', component: AddEditOrderReport, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/order-reports/add', component: AddEditOrderReport, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/products/edit/:id', component: AddEditProduct, role: 'ROLE_PRODUCT_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/products/add', component: AddEditProduct, role: 'ROLE_PRODUCT_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-attributes/edit/:id', component: AddEditProductAttribute, role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-attributes/add', component: AddEditProductAttribute, role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-attribute-groups/edit/:id', component: AddEditProductAttributeGroup, role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-attribute-groups/add', component: AddEditProductAttributeGroup, role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-attribute-sets/edit/:id', component: AddEditProductAttributeSet, role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-attribute-sets/add', component: AddEditProductAttributeSet, role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-categories/edit/:id', component: AddEditProductCategory, role: 'ROLE_PRODUCT_CATEGORY_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-categories/add', component: AddEditProductCategory, role: 'ROLE_PRODUCT_CATEGORY_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-reporting-categories/edit/:id', component: AddEditProductReportingCategory, role: 'ROLE_PRODUCT_CATEGORY_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/product-reporting-categories/add', component: AddEditProductReportingCategory, role: 'ROLE_PRODUCT_CATEGORY_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/stock-change/edit/:id', component: AddEditStockChange, role: 'ROLE_WAREHOUSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/stock-change/add', component: AddEditStockChange, role: 'ROLE_WAREHOUSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/stock-audit', component: StockAuditReport, role: 'ROLE_WAREHOUSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/promotions/edit/:id', component: AddEditPromotion, role: 'ROLE_PROMOTION_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/promotions/add', component: AddEditPromotion, role: 'ROLE_PROMOTION_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/single-use-discounts/edit/:id', component: AddEditSingleUseDiscount, role: 'ROLE_PROMOTION_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/single-use-discounts/add', component: AddEditSingleUseDiscount, role: 'ROLE_PROMOTION_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/quotes/edit/:id', component: EditQuote, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/quotes/add', component: AddOrder, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/order-refunds', component: OrderRefunds, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/redirects/edit/:id', component: AddEditRedirect, role: 'ROLE_REDIRECT_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/redirects/add', component: AddEditRedirect, role: 'ROLE_REDIRECT_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/resource-pages/edit/:id', component: AddEditResourcePage, role: 'ROLE_RESOURCE_PAGE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/resource-pages/add', component: AddEditResourcePage, role: 'ROLE_RESOURCE_PAGE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/sage-order-exports', component: SageOrderExports, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/sage-order-exports/edit/:id', component: AddEditSageOrderExport, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/sage-product-codes', component: SageProductCodes, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/sage-product-codes/edit/:id', component: AddEditSageProductCode, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/sage-product-promotion-codes', component: SageProductPromotionCodes, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/sage-product-promotion-codes/edit/:id', component: AddEditSageProductPromotionCode, role: 'ROLE_ORDER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/shipping-services/edit/:id', component: AddEditShippingService, role: 'ROLE_SHIPPING_SERVICE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/shipping-services/add', component: AddEditShippingService, role: 'ROLE_SHIPPING_SERVICE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/team-memberships/edit/:id', component: AddEditTeamMembership, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/team-memberships/add', component: AddEditTeamMembership, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/users/edit/:id', component: AddEditUser, role: 'ROLE_USER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/users/add', component: AddEditUser, role: 'ROLE_USER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/user-authenticators', component: UserAuthenticators, role: 'ROLE_USER', layout: '/admin', hide: true },
  { route: '/admin/video-courses/edit/:id', component: AddEditVideoCourse, role: 'ROLE_VIDEO_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/video-courses/add', component: AddEditVideoCourse, role: 'ROLE_VIDEO_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/web-components/edit/:id', component: AddEditWebComponent, role: 'ROLE_WEB_COMPONENT_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/web-components/add', component: AddEditWebComponent, role: 'ROLE_WEB_COMPONENT_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/webinar-courses/edit/:id', component: AddEditWebinarCourse, role: 'ROLE_WEBINAR_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/webinar-courses/add', component: AddEditWebinarCourse, role: 'ROLE_WEBINAR_COURSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/web-pages/edit/:id', component: AddEditWebPage, role: 'ROLE_WEB_PAGE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/web-pages/add', component: AddEditWebPage, role: 'ROLE_WEB_PAGE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/warehouse/orders/edit/:id', component: EditOrderWarehouse, role: 'ROLE_WAREHOUSE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/reports/download', component: DownloadReport, role: 'ROLE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/school-learning-groups/edit/:id', component: AddEditSchoolLearningGroup, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/school-learning-groups/add', component: AddEditSchoolLearningGroup, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/custom-learning-groups/edit/:id', component: AddEditCustomLearningGroup, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/custom-learning-groups/add', component: AddEditCustomLearningGroup, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/licences/edit/:id', component: AddEditLicence, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/licences/add', component: AddEditLicence, role: 'ROLE_MEMBERSHIP_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/infinity/learning-groups/edit/:id', component: InfinityConfiguration, role: 'ROLE_USER_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/infinity/onboarding-applications/add', component: AddOnboardingApplication, role: 'ROLE_ADMIN', layout: '/admin', hide: true },
  { route: '/admin/infinity/onboarding-applications/edit/:id', component: EditOnboardingApplication, role: 'ROLE_ADMIN', layout: '/admin', hide: true },
]

export const adminSidebarRoutes = [
  // Dashboard
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'Dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: '/admin/dashboard',
    component: Dashboard,
    noCollapse: true,
  },
  // Warehouse
  {
    type: 'collapse',
    name: 'Warehouse',
    key: 'Warehouse',
    icon: <Icon fontSize="medium">local_shipping</Icon>,
    role: ['ROLE_WAREHOUSE_ADMIN'],
    collapse: [
      // Processing
      {
        name: 'Processing',
        key: 'Warehouse / Processing',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/warehouse/orders/processing',
        component: OrdersWarehouse,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Completed
      {
        name: 'Completed',
        key: 'Warehouse / Completed',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/warehouse/orders/completed',
        component: OrdersWarehouse,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Cancelled
      {
        name: 'Cancelled',
        key: 'Warehouse / Cancelled',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/warehouse/orders/cancelled',
        component: OrdersWarehouse,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock audit
      {
        name: 'Stock audit',
        key: 'Warehouse / Stock audit',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-change',
        component: StockChanges,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock report
      {
        name: 'Stock report',
        key: 'Warehouse / Stock report',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-audit',
        component: StockAuditReport,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock count
      {
        name: 'Stock count',
        key: 'Warehouse / Stock count',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-count',
        component: AddStockCountRequest,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock transfer
      {
        name: 'Stock transfer',
        key: 'Warehouse / Stock transfer',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-change-transfer',
        component: AddStockTransferRequest,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock write off
      {
        name: 'Stock write off',
        key: 'Warehouse / Stock write off',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-change-write-off',
        component: AddStockWriteOffRequest,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Receive stock
      {
        name: 'Receive stock',
        key: 'Warehouse / Receive stock',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-change-receive-stock',
        component: AddStockReceivedRequest,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Order stock
      {
        name: 'Order stock',
        key: 'Warehouse / Order stock',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-change-order-stock',
        component: AddStockOrderRequest,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock Correction
      {
        name: 'Stock correction',
        key: 'Warehouse / Stock correction',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-change-correction',
        component: AddStockCorrectionRequest,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Product bundles
      {
        name: 'Product bundles',
        key: 'Warehouse / Product bundles',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/product-bundles',
        component: ProductBundles,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
      // Stock Draw Down Report
      {
        name: 'Stock draw down',
        key: 'Warehouse / Stock draw down report',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/stock-audit-draw-down-report',
        component: StockAuditDrawDownReport,
        role: ['ROLE_WAREHOUSE_ADMIN'],
      },
    ],
  },
  // Orders
  {
    type: 'collapse',
    name: 'Orders',
    key: 'Orders',
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    role: 'ROLE_ORDER_ADMIN',
    collapse: [
      // All orders
      {
        name: 'All orders',
        key: 'Orders / All orders',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/orders',
        component: Orders,
        role: 'ROLE_ORDER_ADMIN',
      },
      // Quotes
      {
        name: 'Quotes',
        key: 'Orders / Quotes',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/quotes',
        component: Quotes,
        role: 'ROLE_ORDER_ADMIN',
      },
      // Order reports
      {
        name: 'Order reports',
        key: 'Orders / Order reports',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/order-reports',
        component: OrderReports,
        role: 'ROLE_ORDER_ADMIN',
      },
      // Order refunds
      {
        name: 'Order refunds',
        key: 'Orders / Order refunds',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/order-refunds',
        component: OrderRefunds,
        role: 'ROLE_ORDER_ADMIN',
      },
      // Braintree
      {
        name: 'Braintree',
        key: 'Orders / Braintree',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/payment-transactions',
        component: PaymentTransactions,
        role: 'ROLE_ORDER_ADMIN',
      },
    ],
  },
  // infinity
  {
    type: 'collapse',
    name: 'Infinity',
    key: 'Infinity',
    icon: <Icon fontSize="medium">all_inclusive_icon</Icon>,
    role: 'ROLE_ORDER_ADMIN',
    collapse: [
      {
        name: 'Quizzes',
        key: 'Quizzes',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/infinity/quiz-versions',
        component: QuizVersions,
        role: 'ROLE_USER_ADMIN',
      },
      {
        name: 'Onboarding',
        key: 'Onboarding',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/infinity/onboarding-applications',
        component: OnboardingApplications,
        role: 'ROLE_ADMIN',
      },
    ],
  },
  // Products
  {
    type: 'collapse',
    name: 'Products',
    key: 'Products',
    icon: <Icon fontSize="medium">menu_book</Icon>,
    role: 'ROLE_PRODUCT_ADMIN',
    collapse: [
      // All products
      {
        name: 'All products',
        key: 'Products / All products',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/products',
        component: Products,
        role: 'ROLE_PRODUCT_ADMIN',
      },
      // Product attributes
      {
        name: 'Product attributes',
        key: 'Products / Product attributes',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/product-attributes',
        component: ProductAttributes,
        role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN',
      },
      // Attribute groups
      {
        name: 'Attribute groups',
        key: 'Products / Attribute groups',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/product-attribute-groups',
        component: ProductAttributeGroups,
        role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN',
      },
      // Attribute sets
      {
        name: 'Attribute sets',
        key: 'Products / Attribute sets',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/product-attribute-sets',
        component: ProductAttributeSets,
        role: 'ROLE_PRODUCT_ATTRIBUTE_ADMIN',
      },
      // Categories
      {
        name: 'Categories',
        key: 'Products / Categories',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/product-categories',
        component: ProductCategories,
        role: 'ROLE_PRODUCT_CATEGORY_ADMIN',
      },
      // Product reporting categories
      {
        name: 'Reporting categories',
        key: 'Products / Reporting categories',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/product-reporting-categories',
        component: ProductReportingCategories,
        role: 'ROLE_PRODUCT_CATEGORY_ADMIN',
      },
    ],
  },
  // Courses
  {
    type: 'collapse',
    name: 'Courses',
    key: 'Courses',
    icon: <Icon fontSize="medium">school</Icon>,
    role: 'ROLE_VIDEO_COURSE_ADMIN',
    collapse: [
      {
        name: 'Training courses',
        key: 'Courses / Training courses',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/training-courses',
        component: TrainingCourses,
        role: 'ROLE_FACE_TO_FACE_COURSE_ADMIN',
      },
      // Face-to-face courses
      {
        name: 'Face-to-face courses',
        key: 'Courses / Face-to-face courses',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/face-to-face-courses',
        component: FaceToFaceCourses,
        role: 'ROLE_FACE_TO_FACE_COURSE_ADMIN',
      },
      // Video courses
      {
        name: 'Video courses',
        key: 'Courses / Video courses',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/video-courses',
        component: VideoCourses,
        role: 'ROLE_VIDEO_COURSE_ADMIN',
      },
      // Webinars
      {
        name: 'Webinars',
        key: 'Courses / Webinars',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/webinar-courses',
        component: WebinarCourses,
        role: 'ROLE_WEBINAR_COURSE_ADMIN',
      },
    ],
  },
  // Discounts
  {
    type: 'collapse',
    name: 'Discounts',
    key: 'Discounts',
    icon: <Icon fontSize="medium">card_giftcard</Icon>,
    role: 'ROLE_PROMOTION_ADMIN',
    collapse: [
      // Promotions
      {
        name: 'Promotions',
        key: 'Discounts / Promotions',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/promotions',
        component: Promotions,
        role: 'ROLE_PROMOTION_ADMIN',
      },
      // Single use discounts
      {
        name: 'Single use discounts',
        key: 'Discounts / Single use discounts',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/single-use-discounts',
        component: SingleUseDiscounts,
        role: 'ROLE_PROMOTION_ADMIN',
      },
    ],
  },
  // Content
  {
    type: 'collapse',
    name: 'Content',
    key: 'Content',
    icon: <Icon fontSize="medium">bookmarks</Icon>,
    role: ['ROLE_WEB_PAGE_ADMIN', 'ROLE_RESOURCE_PAGE_ADMIN', 'ROLE_HOME_LEARNING_PAGE_ADMIN', 'ROLE_WEB_COMPONENT_ADMIN', 'ROLE_NEWS_ARTICLE_ADMIN'],
    collapse: [
      // Web pages
      {
        name: 'Web pages',
        key: 'Content / Web pages',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/web-pages',
        component: WebPages,
        role: 'ROLE_WEB_PAGE_ADMIN',
      },
      // Resource pages
      {
        name: 'Resource pages',
        key: 'Content / Resource pages',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/resource-pages',
        component: ResourcePages,
        role: 'ROLE_RESOURCE_PAGE_ADMIN',
      },
      // Home learning pages
      {
        name: 'Home learning pages',
        key: 'Content / Home learning pages',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/home-learning-pages',
        component: HomeLearningPages,
        role: 'ROLE_HOME_LEARNING_PAGE_ADMIN',
      },
      // Web components
      {
        name: 'Web components',
        key: 'Content / Web components',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/web-components',
        component: WebComponents,
        role: 'ROLE_WEB_COMPONENT_ADMIN',
      },
      // News articles
      {
        name: 'News articles',
        key: 'Content / News articles',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/news-articles',
        component: NewsArticles,
        role: 'ROLE_NEWS_ARTICLE_ADMIN',
      },
    ],
  },
  // Media
  {
    type: 'collapse',
    name: 'Media',
    key: 'Media',
    icon: <Icon fontSize="medium">image</Icon>,
    route: '/admin/media',
    component: Media,
    role: [
      'ROLE_HOME_LEARNING_PAGE_ADMIN',
      'ROLE_NEWS_ARTICLE_ADMIN',
      'ROLE_PRODUCT_ADMIN',
      'ROLE_RESOURCE_PAGE_ADMIN',
      'ROLE_WEB_COMPONENT_ADMIN',
      'ROLE_WEB_PAGE_ADMIN'
    ],
    noCollapse: true,
    openInNew: true,
  },
  // Users
  {
    type: 'collapse',
    name: 'Users',
    key: 'Users',
    icon: <Icon fontSize="medium">person</Icon>,
    role: 'ROLE_USER_ADMIN',
    collapse: [
      // All users
      {
        name: 'All users',
        key: 'Users / All users',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/users',
        component: Users,
        role: 'ROLE_USER_ADMIN',
      },
      // Memberships
      {
        name: 'Memberships',
        key: 'Users / Memberships',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/memberships',
        component: Memberships,
        role: 'ROLE_MEMBERSHIP_ADMIN',
      },
      // Teams
      {
        name: 'Teams',
        key: 'Users / Teams',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/team-memberships',
        component: TeamMemberships,
        role: 'ROLE_MEMBERSHIP_ADMIN',
      },
      // Membership plans
      {
        name: 'Membership plans',
        key: 'Users / Membership plans',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/membership-plans',
        component: MembershipPlans,
        role: 'ROLE_MEMBERSHIP_PLAN_ADMIN',
      },
    ],
  },
  // Learning Groups
  {
    type: 'collapse',
    name: 'Groups/Licences',
    key: 'Groups',
    icon: <Icon fontSize="medium">group</Icon>,
    role: 'ROLE_MEMBERSHIP_ADMIN',
    collapse: [
      {
        name: 'All Groups',
        key: 'Groups / Groups',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/learning-groups',
        component: LearningGroups,
        role: 'ROLE_MEMBERSHIP_ADMIN',
      },
      {
        name: 'School Groups',
        key: 'Groups / School Groups',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/school-learning-groups',
        component: SchoolLearningGroups,
        role: 'ROLE_MEMBERSHIP_ADMIN',
      },
      {
        name: 'Custom Groups',
        key: 'Groups / Custom Groups',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/custom-learning-groups',
        component: CustomLearningGroups,
        role: 'ROLE_MEMBERSHIP_ADMIN',
      },
      {
        name: 'Licences',
        key: 'Groups / Licences',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/licences',
        component: Licences,
        role: 'ROLE_MEMBERSHIP_ADMIN',
      },
    ],
  },
  // Analytics
  {
    type: 'collapse',
    name: 'Analytics',
    key: 'Analytics',
    icon: <Icon fontSize="medium">bar_chart</Icon>,
    role: 'ROLE_ORDER_ADMIN',
    collapse: [
      // Dashboard
      {
        name: 'Dashboard',
        key: 'Analytics / Dashboard',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/analytics-dashboard',
        component: Analytics,
        role: 'ROLE_MENU_ADMIN',
      },
      // Memberships
      {
        name: 'Memberships',
        key: 'Analytics / Memberships',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/membership-analytics',
        component: MembershipAnalytics,
        role: 'ROLE_MENU_ADMIN',
      },
      // Discounts
      {
        name: 'Discounts',
        key: 'Analytics / Discounts',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/analytics/discounts',
        component: DiscountAnalytics,
        role: 'ROLE_MENU_ADMIN',
      },
    ],
  },
  // Configuration
  {
    type: 'collapse',
    name: 'Configuration',
    key: 'Configuration',
    icon: <Icon fontSize="medium">settings</Icon>,
    role: ['ROLE_MENU_ADMIN', 'ROLE_PAGE_BLOCK_TYPE_ADMIN', 'ROLE_REDIRECT_ADMIN', 'ROLE_SHIPPING_SERVICE_ADMIN', 'ROLE_LOCATION_ADMIN'],
    collapse: [
      // Menus
      {
        name: 'Menus',
        key: 'Configuration / Menus',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/menus',
        component: Menus,
        role: 'ROLE_MENU_ADMIN',
      },
      // Page block types
      {
        name: 'Page block types',
        key: 'Configuration / Page block types',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/page-block-types',
        component: PageBlockTypes,
        role: 'ROLE_PAGE_BLOCK_TYPE_ADMIN',
      },
      // Redirects
      {
        name: 'Redirects',
        key: 'Configuration / Redirects',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/redirects',
        component: Redirects,
        role: 'ROLE_REDIRECT_ADMIN',
      },
      // Shipping services
      {
        name: 'Shipping services',
        key: 'Configuration / Shipping services',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/shipping-services',
        component: ShippingServices,
        role: 'ROLE_SHIPPING_SERVICE_ADMIN',
      },
      // Digital tools
      {
        name: 'Digital tools',
        key: 'Configuration / Digital tools',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/digital-tools',
        component: DigitalTools,
        role: 'ROLE_DIGITAL_TOOL_ADMIN',
      },
      // Locations
      {
        name: 'Locations',
        key: 'Configuration / Locations',
        icon: <Icon fontSize="medium">chevron_right</Icon>,
        route: '/admin/locations',
        component: Locations,
        role: 'ROLE_LOCATION_ADMIN',
      },
    ],
  },
];
