/* eslint-disable */
import { Formik } from 'formik';

import useMounted from 'hooks/use-mounted';
import { useCallback, useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import getDateLocaleString from 'utils/get-date-locale-string';
import getUserName from 'utils/get-user-name';

import { Box, Card, CardActions, CardContent, Grid, Tab, Tabs, TextField } from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import TimelineItem from 'mdpr2/examples/Timeline/TimelineItem';
import TimelineList from 'mdpr2/examples/Timeline/TimelineList';

// WRM
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@mui/icons-material/Comment';
import { TabContext, TabPanel } from '@mui/lab';
import { resourceApi } from 'api/resource-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../contexts/app-context';
import { useUser } from 'contexts/userContext';
import LearningGroupAccesses from '../LearningGroupAccesses';
import LearningGroupLicences from '../LearningGroupLicences';
import LearningGroupConfiguration from '../LearningGroupConfiguration'
import { ConvertLearningGroupModal } from './components/ConvertLearningGroupModal';
import { SplitLearningGroupModal } from './components/SplitLearningGroupModal';
import { MergeLearningGroupModal } from './components/MergeLearningGroupModal';
import axios from 'axios';
import { postRequestQuery } from 'api/apiRequest';
import { isNonEmptyArray, isNonEmptyString } from 'expect-more';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: '0px',
    'margin-top': '0.5em',
  },
}));

const AddEditLearningGroupForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    validationSchema,
    resource: learningGroup,
  } = props;

  const classes = useStyles();
  const isMounted = useMounted();
  const { userHasRole } = useUser();
  const { userFeedbackSuccess } = useAppContext();

  const [noteText, setNoteText] = useState('');
  const [selectedTab, setSelectedTab] = useState('accesses');
  const [learningGroupNoteStories, setLearningGroupNoteStories] = useState([]);
  const [convertLearningGroupModalOpen, setConvertLearningGroupModalOpen] = useState(false);
  const [splitLearningGroupModalOpen, setSplitLearningGroupModalOpen] = useState(false);
  const [convertRequestErrored, setConvertRequestErrored] = useState(false);
  const [splitRequestErrored, setSplitRequestErrored] = useState(false);
  const [schoolAleadyLearningGroup, setSchoolAleadyLearningGroup] = useState(false);
  const [mergeLearningGroupModalOpen, setMergeLearningGroupModalOpen] = useState(false);
  const [mergeRequestErrored, setMergeRequestErrored] = useState(false);

  const mergeLearningGroupFormRef = useRef(null);
  const convertLearningGroupFormRef = useRef(null);
  const splitLearningGroupFormRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const hasSchoolField = fields.some((field) => field.name === 'school');
  const isCreating = location.pathname.includes('/add');

  const loadLearningGroupNotes = async () => {
    const learningGroupNotesResponse = await resourceApi.getResources({
      apiEndpoint: 'learning-group-notes',
      filterValues: {
        learningGroup: `/learning-groups/${learningGroup.id}`,
      },
      sortValues: [
        { field: 'createdAt', direction: 'desc' },
        { field: 'id', direction: 'desc' },
      ],
      pagination: {
        itemsPerPage: 999,
      },
    });
    const teamMembershipNotes = learningGroupNotesResponse.resources;
    // eslint-disable-next-line no-shadow
    const learningGroupNoteStories = [];
    teamMembershipNotes.forEach((learningGroupNote) => {
      learningGroupNoteStories.push({
        description: learningGroupNote.content,
        title: renderToStaticMarkup(
          <>
            {getDateLocaleString(learningGroupNote.createdAt)}
            {learningGroupNote.createdByUser && <>&nbsp;by {getUserName(learningGroupNote.createdByUser)}</>}
          </>
        ),
        dateTime: '',
        color: 'warning',
        icon: 'comment',
        key: `learning-grop-note-${learningGroupNote.id}`,
      });
    });
    setLearningGroupNoteStories(learningGroupNoteStories);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabsSection = () => {
    // don't show until the group has been created
    if (!learningGroup.id) {
      return <></>;
    }

    return (
      <Box marginBottom={2}>
        <Card>
          <CardContent>
            <TabContext value={selectedTab}>
              <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Tabs">
                <Tab label="Group Access" value="accesses" />
                <Tab label="Owned Licences" value="licences" />
              </Tabs>

              <TabPanel value="accesses" variant="fullWidth" className={classes.tabPanel}>
                <LearningGroupAccesses learningGroup={learningGroup} />
              </TabPanel>
              <TabPanel value="licences" className={classes.tabPanel}>
                <LearningGroupLicences learningGroup={learningGroup} />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const initialise = useCallback(async () => {
    if (isMounted()) {
      await loadLearningGroupNotes();
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  const createNewLearningGroupNote = async () => {
    const data = {
      learningGroup: `/learning-groups/${learningGroup.id}`,
      content: noteText,
    };

    await resourceApi.saveResource({
      apiEndpoint: 'learning-group-notes',
      data,
      id: null,
    });

    userFeedbackSuccess('Note added successfully');

    setNoteText('');

    await loadTeamMembershipNotes();
  };

  const onSubmitConversionForm = async () => {
    if (!convertLearningGroupFormRef.current) return;
    // Submit the form
    convertLearningGroupFormRef.current.handleSubmit();
    if (convertLearningGroupFormRef.current.isValid) {
      const { values } = convertLearningGroupFormRef.current;
      const { school } = values;
      const { id } = school;

      let schoolId = id.split('/schools/')[1];
      const sourceId = learningGroup?.id;

      // Check we dont have an empty after splitting just to be safe
      if (typeof schoolId === 'string' && schoolId.length) {
        try {
          // make request to merge source with school
          await axios(postRequestQuery(`learning-groups/${sourceId}/convert-to-school-learning-group/${schoolId}`, {}));
          // if we had shown an error then remove the alert if we get past the request
          setConvertRequestErrored(false);
          // navigate to resource
          navigate(`/admin/school-learning-groups/edit/${sourceId}`);
        } catch (_e) {
          setConvertRequestErrored(true);
        }
      }
    }
  };

  const onSubmitMergeForm = async () => {
    if (!mergeLearningGroupFormRef.current) return;

    const {
      values: {
        learningGroup: { id: selectedLearningGroupId },
      },
    } = mergeLearningGroupFormRef.current;

    const sourceId = learningGroup?.id;

    if (selectedLearningGroupId > 0) {
      try {
        await axios(postRequestQuery(`learning-groups/${sourceId}/merge/${selectedLearningGroupId}`, {}));

        setMergeRequestErrored(false);
        setMergeLearningGroupModalOpen(false);
        navigate(`/admin/school-learning-groups/edit/${selectedLearningGroupId}`);
      } catch (_e) {
        setMergeRequestErrored(true);
      }
    } else {
      setMergeRequestErrored(true);
    }
  };

  const handleOpenLearningGroupModal = () => {
    setConvertLearningGroupModalOpen(true);
  };

  const handleOpenSplitGroupModal = () => {
    setSplitLearningGroupModalOpen(true);
  };

  const onSubmitSplitForm = async () => {
    if (!splitLearningGroupFormRef.current) return;

    const {
      values: { groupName, licences, users },
    } = splitLearningGroupFormRef.current;
    const groupId = learningGroup?.id;

    if (isNonEmptyString(groupName) && isNonEmptyArray(users)) {
      try {
        // convert strings to keep the api happy
        const convertedUsers = users.map((value) => {
          return parseInt(value);
        });
        const convertedLicences = licences.map((value) => {
          return parseInt(value);
        });

        const payload = {
          name: groupName,
          users: convertedUsers,
          licences: convertedLicences,
        };

        const response = await axios(postRequestQuery(`learning-groups/${groupId}/split`, payload));

        setSplitRequestErrored(false);

        const { id } = response?.data;
        setSplitLearningGroupModalOpen(false);
        navigate(`/admin/custom-learning-groups/edit/${id}`);
      } catch (_e) {
        setSplitRequestErrored(true);
      }
    } else {
      setSplitRequestErrored(true);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box marginBottom={2}>
                <Card>
                  <CardContent>
                    <Grid container mt={1} spacing={2}>
                      <Grid item xs={7} mr={1}>
                        <FormField
                          {...fields.find((field) => field.name === 'name')}
                          formik={formik}
                          getApiError={getApiError}
                        />
                      </Grid>
                      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexGrow={1}>
                        {!hasSchoolField && !isCreating && userHasRole('ROLE_DEVELOPER') && (
                          <Box display="flex" paddingLeft={1}>
                            <MDButton
                              color="info"
                              disabled={formik.isSubmitting}
                              onClick={() => handleOpenLearningGroupModal()}
                              size="medium"
                              variant="gradient"
                              data-testid="convert-group-modal-button"
                            >
                              Convert group
                            </MDButton>
                          </Box>
                        )}

                        {!isCreating && userHasRole('ROLE_DEVELOPER') && (
                          <Box display="flex" paddingLeft={1}>
                            <MDButton
                              color="info"
                              disabled={formik.isSubmitting}
                              onClick={() => handleOpenSplitGroupModal()}
                              size="medium"
                              variant="gradient"
                              data-testid="split-group-modal-button"
                            >
                              Split group
                            </MDButton>
                          </Box>
                        )}

                        {!isCreating && userHasRole('ROLE_DEVELOPER') && (
                          <Box display="flex" paddingLeft={1}>
                            <MDButton
                              color="info"
                              disabled={formik.isSubmitting}
                              onClick={() => setMergeLearningGroupModalOpen(!mergeLearningGroupModalOpen)}
                              size="medium"
                              variant="gradient"
                              data-testid="merge-group-modal-button"
                            >
                              Merge group
                            </MDButton>
                          </Box>
                        )}
                      </Box>

                      {convertLearningGroupModalOpen && (
                        <ConvertLearningGroupModal
                          formRef={convertLearningGroupFormRef}
                          convertRequestErrored={convertRequestErrored}
                          setconvertLearningGroupModalOpen={setConvertLearningGroupModalOpen}
                          onSubmitForm={onSubmitConversionForm}
                          schoolAleadyLearningGroup={schoolAleadyLearningGroup}
                        />
                      )}

                      {splitLearningGroupModalOpen && (
                        <SplitLearningGroupModal
                          formRef={splitLearningGroupFormRef}
                          splitRequestErrored={splitRequestErrored}
                          setSplitLearningGroupModalOpen={setSplitLearningGroupModalOpen}
                          onSubmitForm={onSubmitSplitForm}
                          id={learningGroup?.id}
                        />
                      )}

                      {mergeLearningGroupModalOpen && (
                        <MergeLearningGroupModal
                          formRef={mergeLearningGroupFormRef}
                          mergeRequestErrored={mergeRequestErrored}
                          setMergeLearningGroupModal={setMergeLearningGroupModalOpen}
                          onSubmitForm={onSubmitMergeForm}
                        />
                      )}

                      {hasSchoolField && (
                        <FormField
                          {...fields.find((field) => field.name === 'school')}
                          formik={formik}
                          getApiError={getApiError}
                        />
                      )}
                    </Grid>
                  </CardContent>
                  <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                    <SaveCancelButtons cancelLocation={cancelLocation} formik={formik} />
                  </CardActions>
                </Card>
              </Box>
              {tabsSection()}
            </Grid>
            <Grid item xs={4} style={{ maxHeight: '10rem' }}>
              {learningGroup.id && (
                <>
                  {userHasRole('ROLE_DEVELOPER') && (
                    <MDButton
                      color="info"
                      onClick={() => window.open(`/admin/infinity/learning-groups/edit/${learningGroup.id}`)}
                      size="medium"
                      variant="gradient"
                      sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}
                    >
                      Infinity config
                      <OpenInNewIcon margin={10} />
                    </MDButton>
                  )}
                  <Grid item marginTop={1} xs={12}>
                    <TextField
                      fullWidth
                      label="Add Note"
                      multiline
                      name="add-note"
                      rows={3}
                      placeholder="New Note Here"
                      onChange={(e) => setNoteText(e.target.value)}
                      value={noteText}
                    />
                    <MDButton
                      sx={{ mt: 1 }}
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={createNewLearningGroupNote}
                    >
                      <CommentIcon sx={{ mr: 1 }} /> Add Note
                    </MDButton>
                  </Grid>
                </>
              )
              }
              <TimelineList title="Notes">
                {learningGroupNoteStories.map((learningGroupNoteStory) => (
                  <TimelineItem {...learningGroupNoteStory} />
                ))}
              </TimelineList>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

AddEditLearningGroupForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditLearningGroupForm;
